
import Vue, { PropType } from 'vue'
import InlineIcon from '~/components/_general/InlineIcon.vue'
import SiteButton, {
  ButtonColor,
  ButtonSize,
} from '~/components/site/Button.vue'
import { SaIconsType } from '~/assets/ts/types/icons'

export default Vue.extend({
  name: 'FancyDropdownTarget',
  components: { InlineIcon },
  props: {
    clickable: {
      type: Boolean,
      default: true,
    },
    button: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String as PropType<SaIconsType>,
      default: '',
    },
  },
  data() {
    return {}
  },
  computed: {
    SiteButton() {
      return SiteButton
    },
    buttonProps(): Record<string, any> | undefined {
      if (!this.button) return undefined
      return {
        disabled: !this.clickable,
        buttonColor: 'gray-light' as ButtonColor,
        size: 'normal' as ButtonSize,
        visualOnly: true,
      }
    },
  },
  methods: {},
})
