
import Vue, { PropType } from 'vue'
import SaImage from '~/components/_general/SaImage.vue'
import { FancyDropdownOption } from '~/components/_general/FancyDropdown.vue'
import KeydownEvent from '~/models/generic/KeydownEvent'
import KeydownObserver from '~/components/_general/KeydownObserver.vue'
import SaIcon from '~/components/_general/SaIcon.vue'
import { waitOneFrame } from '~/assets/ts/utils/misc'
import { clamp } from '~/assets/ts/utils/math'

export default Vue.extend({
  name: 'FancyDropdownPageContents',
  components: { SaIcon, KeydownObserver, SaImage },
  props: {
    options: {
      type: Array as PropType<FancyDropdownOption[]>,
      required: true,
    },
    currentValue: {
      type: String,
      default: undefined,
    },
    imageWidth: {
      type: Number,
      default: 96,
    },
    opened: {
      type: Boolean,
      required: true,
    },
    parent: {
      type: Boolean,
    },
  },
  computed: {
    currentIndex(): number {
      return this.options.findIndex((o) => o.value === this.currentValue)
    },
  },
  destroyed() {
    this.$off('focus', this.focus)
  },
  mounted() {
    this.$on('focus', this.focus)
  },
  methods: {
    async focus(index: number | undefined) {
      await waitOneFrame()
      const options = this.$refs.options as HTMLElement[]
      if (!options || !options.length) return
      index = clamp(index || 0, 0, options.length - 1)
      options[index].focus()
    },
    change(option: FancyDropdownOption) {
      if (option.disabled) return
      this.$emit('change', option.value)
    },
    keydown(key: KeydownEvent) {
      if (key.Escape) {
        this.close()
      } else if (key.ArrowUp) {
        this.focusSibling(key.event)
      } else if (key.ArrowDown) {
        this.focusSibling(key.event, true)
      }
    },
    focusSibling(event: KeyboardEvent, next = false) {
      event.preventDefault()
      const options = this.$refs.options as (HTMLElement | undefined)[]
      let el = event.target as HTMLElement | undefined
      let selectNext = true
      if (options && options.length && !options.includes(el)) {
        if (this.currentIndex === -1) {
          selectNext = false
          el = options[0]
        } else {
          el = options[this.currentIndex]
        }
      }
      if (el && selectNext) {
        el = (next ? el.nextElementSibling : el.previousElementSibling) as
          | HTMLElement
          | undefined
      }
      if (!el) return
      el.focus()
    },
    close() {
      this.$emit('close')
    },
  },
})
