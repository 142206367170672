
import Vue, { PropType } from 'vue'
import { TranslateResult } from 'vue-i18n'
import CustomDropdown, { CustomDropdownProps } from './CustomDropdown.vue'
import { SaIconsType } from '~/assets/ts/types/icons'
import { DropdownNullDisplay } from '~/components/_general/Dropdown.vue'
import FancyDropdownTarget from '~/components/fancyDropdown/Target.vue'
import FancyDropdownPageContents from '~/components/fancyDropdown/PageContents.vue'

export interface FancyDropdownOption {
  value: string
  title: TranslateResult
  subtitle?: TranslateResult
  imageURL?: string
  roundImage?: boolean
  disabled?: boolean
}

export function NullableFancyDropdownOptions(
  options: FancyDropdownOption[],
  nullValue: any = ''
) {
  return [
    { value: nullValue, title: DropdownNullDisplay },
    ...options,
  ] as FancyDropdownOption[]
}

export default Vue.extend({
  name: 'FancyDropdown',
  components: {
    FancyDropdownPageContents,
    FancyDropdownTarget,
    CustomDropdown,
  },
  inheritAttrs: false,
  model: {
    prop: 'currentValue',
    event: 'change',
  },
  props: {
    ...CustomDropdownProps,
    // must be a translated string that includes the {selection} parameter
    selectionText: {
      type: String as PropType<TranslateResult>,
      default: '',
      validator(value: TranslateResult) {
        if (!value) return true
        return value.toString().includes('{selection}')
      },
    },
    customText: {
      type: String as PropType<TranslateResult>,
      default: '',
    },
    currentValue: {
      type: String,
      default: undefined,
    },
    options: {
      type: Array as PropType<FancyDropdownOption[]>,
      required: true,
    },
    icon: {
      type: String as PropType<SaIconsType>,
      default: '',
    },
    button: {
      type: Boolean,
      default: true,
    },
    imageWidth: {
      type: Number,
      default: 96,
    },
  },
  data() {
    return {
      opened: false,
    }
  },
  computed: {
    currentOption(): FancyDropdownOption | undefined {
      return this.options.find((o) => o.value === this.currentValue)
    },
    currentTitle(): TranslateResult {
      return this.currentOption?.title ?? ''
    },
    title(): TranslateResult {
      if (this.customText) return this.customText
      return this.$t(this.selectionText.toString(), {
        selection: this.currentTitle,
      })
    },
  },
  destroyed() {
    this.$off('open', this.open)
    this.$off('close', this.close)
    this.$off('scrollTop', this.scrollTop)
  },
  mounted() {
    this.$on('open', this.open)
    this.$on('close', this.close)
    this.$on('scrollTop', this.scrollTop)
  },
  methods: {
    scrollTop() {
      const dropdown = this.$refs.dropdown as Vue
      dropdown.$emit('scrollTop')
    },
    change(value: string) {
      this.$emit('change', value)
      this.close()
    },
    close() {
      const dropdown = this.$refs.dropdown as Vue
      dropdown.$emit('close')
    },
    open() {
      const dropdown = this.$refs.dropdown as Vue
      dropdown.$emit('open')
    },
    openedEvent(open: boolean) {
      this.opened = open
      this.$emit(open ? 'opened' : 'closed')
    },
  },
})
