export default class KeydownEvent {
  event: KeyboardEvent
  key: string
  code: number
  input: boolean
  constructor(event: KeyboardEvent) {
    this.event = event
    this.key = event.key
    this.code = event.which
    const target = event.target as HTMLElement | undefined

    const tag = target?.tagName.toLowerCase()
    this.input = !!(tag && (tag.includes('input') || tag.includes('textarea')))
    console.debug(
      `Key: "${this.key}"\nKeycode: ${this.code}\nInput: ${this.input}`
    )
  }

  get Shift(): boolean {
    return this.event.shiftKey
  }

  get Alt(): boolean {
    return this.event.altKey
  }

  get Ctrl(): boolean {
    return this.event.ctrlKey
  }

  get Backspace(): boolean {
    return this.modifiers(this.code === 8)
  }

  get Tab(): boolean {
    return this.modifiers(this.code === 9)
  }

  get ShiftTab(): boolean {
    return this.modifiers(this.code === 9, true)
  }

  get Enter(): boolean {
    return this.modifiers(this.code === 13)
  }

  get ShiftEnter(): boolean {
    return this.modifiers(this.code === 13, true)
  }

  get Space(): boolean {
    return this.modifiers(this.code === 32)
  }

  get Escape(): boolean {
    return this.modifiers(this.code === 27)
  }

  get ArrowUp(): boolean {
    return this.modifiers(this.code === 38)
  }

  get ArrowDown(): boolean {
    return this.modifiers(this.code === 40)
  }

  get ArrowLeft(): boolean {
    return this.modifiers(this.code === 37)
  }

  get ArrowRight(): boolean {
    return this.modifiers(this.code === 39)
  }

  get Arrow(): boolean {
    return this.modifiers(this.code >= 37 && this.code <= 40)
  }

  get A(): boolean {
    return this.modifiers(this.code === 65)
  }

  get C(): boolean {
    return this.modifiers(this.code === 67)
  }

  get D(): boolean {
    return this.modifiers(this.code === 68)
  }

  get F(): boolean {
    return this.modifiers(this.code === 70)
  }

  get I(): boolean {
    return this.modifiers(this.code === 73)
  }

  get J(): boolean {
    return this.modifiers(this.code === 74)
  }

  get K(): boolean {
    return this.modifiers(this.code === 75)
  }

  get L(): boolean {
    return this.modifiers(this.code === 76)
  }

  get M(): boolean {
    return this.modifiers(this.code === 77)
  }

  get CtrlM(): boolean {
    return this.modifiers(this.code === 77, false, true, false)
  }

  get S(): boolean {
    return this.modifiers(this.code === 83)
  }

  get T(): boolean {
    return this.modifiers(this.code === 84)
  }

  get V(): boolean {
    return this.modifiers(this.code === 86)
  }

  get Comma(): boolean {
    return this.modifiers(this.code === 188)
  }

  get Period(): boolean {
    return this.modifiers(this.code === 190)
  }

  get Slash(): boolean {
    return this.modifiers(this.code === 191)
  }

  get GreaterThan(): boolean {
    return this.modifiers(this.code === 190, true)
  }

  get LessThan(): boolean {
    return this.modifiers(this.code === 188, true)
  }

  get QuestionMark(): boolean {
    return this.modifiers(this.code === 191, true)
  }

  get Number(): boolean {
    return this.modifiers(this.code >= 48 && this.code <= 57)
  }

  modifiers(code: boolean, shift = false, ctrl = false, alt = false): boolean {
    return (
      code && this.Shift === shift && this.Alt === alt && this.Ctrl === ctrl
    )
  }
}
