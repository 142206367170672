
import Vue from 'vue'
import KeydownEvent from '~/models/generic/KeydownEvent'

export default Vue.extend({
  name: 'KeydownObserver',
  props: {
    allowInputEvents: {
      type: Boolean,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keydown)
  },
  destroyed() {
    window.removeEventListener('keydown', this.keydown)
  },
  methods: {
    keydown(event: KeyboardEvent) {
      if (!this.active) return
      const key = new KeydownEvent(event)
      if (!this.allowInputEvents && key.input) return
      this.$emit('keydown', key)
    },
  },
})
